import { useState, useMemo, useEffect, useCallback } from 'react';
import { useEventListener } from './use-event-listener.hook';

import { WindowEventListeners } from '../globals/constants';

export enum WindowSizeType {
  MOBILE,
  WEB,
}

export interface Breakpoints {
  label: string;
  value: number;
  type: WindowSizeType;
}

export const breakpoints = {
  xxs: { label: 'xxs', value: 420, type: WindowSizeType.MOBILE },
  xs: { label: 'xs', value: 575, type: WindowSizeType.MOBILE },
  sm: { label: 'sm', value: 768, type: WindowSizeType.MOBILE },
  md: { label: 'md', value: 992, type: WindowSizeType.WEB },
  lg: { label: 'lg', value: 1200, type: WindowSizeType.WEB },
  lg_two_columns_cuttoff: { label: 'lg_two_columns_cuttoff', value: 1281, type: WindowSizeType.WEB },
  xl: { label: 'xl', value: 1400, type: WindowSizeType.WEB },
  xxl: { label: 'xxl', value: 1600, type: WindowSizeType.WEB },
};

/**
 * Hook that returns if the min or max size matches with the current window size using media queries.
 *
 * @param minSize breakPointMinSize
 * @param maxSize breakPointMaxSize
 * @returns
 */
export const useWindowSizeBreakpoints = (minSize: Breakpoints = null, maxSize: Breakpoints = null) => {
  const [matches, setMatches] = useState<boolean>(null);
  const mediaQuery = useMemo(() => {
    let query = '';
    query += minSize ? `(min-width: ${minSize.value}px)` : '';
    query += !!minSize && !!maxSize ? ' and ' : '';
    query += maxSize ? `(max-width: ${maxSize.value}px)` : '';
    return query;
  }, [minSize, maxSize]);
  const media = !!window?.matchMedia ? window.matchMedia(mediaQuery) : null;

  const onChange: any = useCallback((mql: MediaQueryList) => {
    setMatches(mql.matches);
  }, []);

  useEffect(() => {
    setMatches(media?.matches);
  }, [minSize, maxSize, mediaQuery]);

  useEventListener(WindowEventListeners.CHANGE, onChange, media);

  return matches;
};
