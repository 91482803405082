import { useEffect, useRef } from "react";

/**
 * A React hook that allows to create an event listener over the window object.
 * 
 * @param eventName The event name to be listen.
 * @param callback Event listener
 * @param element Window object
 */
export const useEventListener = (eventName: string, callback: (event: any) => void, element: any = window) => {
  const savedHandler = useRef<(event: any) => void>();

  useEffect(() => {
    savedHandler.current = callback;
  }, [callback]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
